<template>
  <div class="root-content">
    <el-image
      :src="require('@/assets/phone.svg')"
      style="display: flex; position: absolute; top: 0; left: 0; z-index: 100"
    />

    <el-carousel
      class="carousel"
      height="790px"
      indicator-position="outside"
      style="position: absolute; top: 18px; left: 27px; width: 379px"
    >
      <el-carousel-item v-for="item in images" :key="item">
        <el-image
          fit="fill"
          :src="item"
          style="display: flex; width: 100%; height: 100%"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "Preview",
  props: {},
  data() {
    return {
      images: [
        require("@/assets/pic_app1.jpg"),
        require("@/assets/pic_app2.jpg"),
        require("@/assets/pic_app3.jpg"),
        require("@/assets/pic_app4.jpg"),
        require("@/assets/pic_app5.jpg"),
      ],
    };
  },
  watch: {},
  methods: {},
};
</script>

<style scoped>
.root-content {
  position: relative;
  overflow: hidden;
  width: 433px;
  height: 808px;
}
</style>
