import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Button, Dialog, Carousel, CarouselItem, Image } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.config.productionTip = false;
// element-ui config
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Image);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
