<template>
  <div
    style="
      display: flex;
      justify-content: space-between;
      padding: 30px 37px 0 37px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    "
  >
    <el-image :src="require('@/assets/logo.svg')" />
    <div style="display: flex; align-items: center">
      <label
        style="
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC, serif;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
        "
        >联系我们：0579-82923510</label
      >
      <el-button
        v-if="!isMobile()"
        plain
        @click="$emit('clickDownloadButton')"
        size="small"
        style="
          background: transparent;
          color: white;
          border-color: white;
          margin-left: 15px;
        "
        >立即下载</el-button
      >
    </div>
  </div>
</template>

<script>
import helps from "@/tools/helps";

export default {
  name: "TopBar",
  props: {},
  data() {
    return {};
  },
  watch: {},
  methods: {
    isMobile() {
      return helps.isMobile();
    },
  },
};
</script>

<style lang="scss" scoped></style>
