<template>
  <div style="display: flex; flex: 1; justify-content: space-between">
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      "
    >
      <el-image
        style="width: 240px; height: 240px"
        :src="require('@/assets/download-qr.png')"
      />
      <label style="margin-top: 15px">扫码下载肥猫商城APP</label>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      "
    >
      <el-image
        style="width: 240px; height: 240px"
        :src="require('@/assets/wx-qr.png')"
      />
      <label style="margin-top: 15px">扫码关注肥猫商城微信号</label>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      "
    >
      <el-image
        style="width: 240px; height: 240px"
        :src="require('@/assets/web-qr.png')"
      />
      <label style="margin-top: 15px">扫码体验网页版</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "QRView",
  props: {},
  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
