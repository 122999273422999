<template>
  <div class="home_pc">
    <top-bar @clickDownloadButton="dialogVisible = true" />
    <div style="display: flex; height: 100%">
      <div style="display: flex; height: 100%; align-items: flex-end">
        <description
          style="margin-bottom: 224px"
          @clickDownloadButton="dialogVisible = true"
        />
      </div>
      <div style="width: 90px"></div>
      <div
        style="
          display: flex;
          height: 100%;
          align-items: flex-end;
          position: relative;
        "
      >
        <preview />
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="800px"
      :modal-append-to-body="false"
      top="35vh"
      title="请使用微信扫一扫哦！"
    >
      <q-r-view />
    </el-dialog>
    <div
      style="
        display: flex;
        width: 100%;
        height: 44px;
        align-items: center;
        justify-content: center;
        background: #333;
      "
    >
      <a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="_blank"
        style="color: white; text-align: center"
        >浙ICP备16011787号-1</a
      >
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar";
import Description from "@/components/Description";
import Preview from "@/components/Preview";
import QRView from "@/components/QRView";
import helps from "@/tools/helps";
export default {
  name: "Home",
  components: { QRView, Preview, Description, TopBar },
  data() {
    return {
      dialogVisible: false,
    };
  },
  created() {
    if (helps.isMobile()) {
      this.$router.replace("/mobile");
    }
  },
};
</script>

<style scoped>
.home_pc {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  background: url("../assets/bg_pc.jpg");
  background-size: cover;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: scroll;
}
</style>
