<template>
  <div
    class="test"
    style="
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 583px;
    "
  >
    <label
      style="
        font-size: 60px;
        font-family: PingFangSC-Semisolid, PingFang SC, serif;
        font-weight: 600;
        color: #ffffff;
        line-height: 84px;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
        margin-bottom: 24px;
      "
      >肥猫商城</label
    >
    <label
      style="
        font-size: 40px;
        font-family: PingFangSC-Regular, PingFang SC, serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 56px;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
        margin-bottom: 24px;
      "
      >品质保证 吃遍全国</label
    >
    <label
      style="
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC, serif;
        font-weight: 400;
        color: #ffffff;
        line-height: 30px;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
        text-align: left;
      "
      >这里是肥猫官网<br />
      肥猫创立至2021年是第9个年头，“品质保证、吃遍全国”是肥猫的宗旨！<br />
      肥猫的用户群体以70、80、90后宝妈为主！<br />
      在产品的定位上，肥猫专注健康又好吃的标签<br />
      给广大的用户提供放心的美食！</label
    >
    <div style="margin-top: 90px">
      <el-button @click="$emit('clickDownloadButton')">下载APP</el-button>
      <el-button
        plain
        style="
          background: transparent;
          color: white;
          border-color: white;
          margin-left: 15px;
        "
        @click="clickHandler"
        >使用网页端</el-button
      >
    </div>
    <el-dialog
      :visible.sync="webDialogVisible"
      width="500px"
      :modal-append-to-body="false"
    >
      <iframe
        src="https://wap.feelmao.com"
        style="
          width: 375px;
          height: 812px;
          background: white;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
          border-width: 0;
        "
      />
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Description",
  props: {},
  data() {
    return {
      webDialogVisible: false,
    };
  },
  watch: {},
  methods: {
    clickHandler() {
      this.webDialogVisible = true;
      console.log("出发了");
    },
  },
};
</script>

<style lang="scss" scoped></style>
